<template>
  <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogVisible"
      width="30%"
      title=""
      class="feedback-dialog"
      align="center"
      @close="cancel">
    <div slot="title" class="fwb f17">打分评价</div>
    <div>
      <el-form :model="dataForm" :rules="dataRule" label-position="left" label-width="auto">
        <el-form-item label="是否匿名：" class="rate" label-width="auto">
          <el-radio-group v-model="dataForm.isShow" v-for="item in this.anonymousList" :key="item.dictId" >
            <el-radio class="pr-3" :label="item.dictId">{{item.dictName}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item   label="产品评分：" prop="productScore" class="rate" >
          <el-rate
              v-model="dataForm.productScore"
              :colors="colors"
              :texts="['极差', '差', '一般', '满意', '非常满意']"
              show-text>
          </el-rate>
        </el-form-item>
        <el-form-item  label="服务商评分：" prop="supportScore" class="rate" >
          <el-rate
              v-model="dataForm.supportScore"
              :colors="colors"
              :texts="['极差', '差', '一般', '满意', '非常满意']"
              show-text>
          </el-rate>
        </el-form-item>
        <el-form-item prop="comment" label="评价内容：">
          <el-input
              type="textarea"
              placeholder="请输入评价内容..."
              v-model="dataForm.comment"
              :rows="12"
              maxlength="500"
              show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="save">确定</el-button>
        </span>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        dataForm: {
          productScore: null,
          comment: '',
          supportScore:null,
          isShow:''
        },
        colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
        dataRule: {},
        anonymousList: [],
        initList:{},
      }
    },
    created() {
      this.$getDictList('COMMON_0_1', dict => {
        this.anonymousList = dict['COMMON_0_1']
      })
    },
    computed:{
      userHead:{
        get(){
          return this.$store.state.user.userHead
        }
      },
      name:{
        get(){
          return this.$store.state.user.name
        }
      }
    },
    methods: {
      init(item) {
        this.dialogVisible = true
        this.initList=item
        // this.dataForm.push(item)
      },
      cancel() {
        this.dialogVisible = false
        this.$emit('evaluateCallback')

      },
      save() {
        this.$confirm('确定完成评论吗？','提示',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          this.$http({
            url:this.$http.adornUrl(this.$api.ORDER_COMMENT.SAVE),
            method:'post',
            data:this.$http.adornData({
              orderId: this.initList.id,
              serviceId:this.initList.serviceId,
              userId:this.initList.userId,
              productId:this.initList.productId,
              enterpriseId:this.initList.enterpriseId,
              userName: this.name,
              userHead: this.userHead,
              productScore:this.dataForm.productScore,
              supportScore:this.dataForm.supportScore,
              comment:this.dataForm.comment,
              isShow:this.dataForm.isShow
            })
          }).then(({data})=>{
            if(data && data.code===0){
              this.dialogVisible=false
              this.$message.success('操作成功')
            }else{
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
<style scoped>
  .feedback-dialog .rate >>> .el-form-item__content {
    vertical-align: middle;
    display: flex;
    align-items: center;
    height: 36px;
  }

  .feedback-dialog .rate >>> .el-rate {
    height: auto;
  }

  .feedback-dialog >>> .el-rate__icon {
    font-size: 26px;
  }
</style>
