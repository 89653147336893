<template>
  <div class="mod-config">
    <template v-if="page === 'list'">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList(true)">
        <el-form-item>
          <el-input v-model="dataForm.orderNo" placeholder="订单号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.needNo" placeholder="方案号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.serviceName" placeholder="产品名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <service-item-cascader v-model="dataForm.itemCode"></service-item-cascader>
        </el-form-item>

        <el-form-item>
          <el-select v-model="dataForm.orderStatus" multiple collapse-tags placeholder="请选择订单状态" clearable>
            <el-option
                v-for="(item, key) in orderStatusDict"
                :key="key"
                :label="item"
                :value="key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.payStatus" placeholder="请选择支付状态" clearable>
            <el-option
                v-for="(item, key) in payStatusDict"
                :key="key"
                :label="item"
                :value="key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
              v-model="dataForm.dateRange"
              value-format="yyyy-MM-dd"
              type="daterange"
              unlink-panels
              :clearable="false"
              :pickerOptions="pickerOptions"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList(true)">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
          :data="dataTableList"
          border
          v-loading="dataListLoading"
          @selection-change="selectionChangeHandle"
          style="width: 100%;">
        <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50">
        </el-table-column>
        <el-table-column
            prop="orderNo"
            header-align="center"
            align="center"
            label="订单编号">
        </el-table-column>
        <el-table-column
            prop="serviceName"
            header-align="center"
            align="center"
            label="需求产品">
        </el-table-column>
        <el-table-column
            prop="orderStatus"
            header-align="center"
            align="center"
            label="订单状态">
          <template slot-scope="scope">
            <el-tag :type="tagType[scope.row.status]">{{ orderStatusDict[scope.row.orderStatus] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="payStatus"
            header-align="center"
            align="center"
            label="付款状态">
          <template slot-scope="scope">
            <el-tag :type="payTagType[scope.row.payStatus]">{{ payStatusDict[scope.row.payStatus] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
                prop="payStatus"
                header-align="center"
                align="center"
                label="发票状态">
          <template slot-scope="scope">
            <el-tag :type="invoiceApplyStatusType[scope.row.invoiceApplyStatus]">{{ invoiceApplyStatusDict[scope.row.invoiceApplyStatus] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
        prop="commentStatus"
        header-align="center"
        align="center"
        min-width="110"
        label="评价状态">
          <template slot-scope="scope" >
            <el-tag :type="commentType[scope.row.commentStatus]">{{commentStatusDict[scope.row.commentStatus]}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="contacts"
            header-align="center"
            align="center"
            label="联系人">
        </el-table-column>
        <el-table-column
            prop="mobile"
            header-align="center"
            align="center"
            show-overflow-tooltip
            label="联系电话">
        </el-table-column>
        <el-table-column
            prop="createTime"
            header-align="center"
            align="center"
            label="成交时间">
        </el-table-column>
        <el-table-column
            prop="totalSum"
            header-align="center"
            align="center"
            label="金额(元)">
          <template slot-scope="scope">
            {{!((scope.row.totalSum || 0) >=0) ? '面议' :  (scope.row.totalSum || 0).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            header-align="center"
            align="center"
            width="150"
            label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.payTotalSum !== scope.row.totalSum" type="text" size="small"
                       @click="openPayment(scope.row)">订单支付
            </el-button>
            <!--          <el-button v-if="scope.row.payStatus === 2" type="text" size="small" @click="orderRefund(scope.row)">申请退款</el-button>-->
            <el-button type="text" size="small" @click="changePage('detail', scope.row)">查看详情</el-button>
            <!-- <el-button type="text" size="small" @click="deleteHandle(scope.row.id)"
                       :disabled="scope.row.payStatus != 1">
              删除订单
            </el-button> -->
            <!--          v-if="scope.row.orderStatus===3"-->
            <el-button v-if="scope.row.payStatus==21 || scope.row.payStatus==22" type="text" size="small" @click="showRefund(scope.row.id)">申请退款</el-button>
            <el-button v-if="scope.row.payStatus==3" type="text" size="small">退款审核中</el-button>
            <el-button :disabled="scope.row.orderStatus!==3" type="text" size="small" @click="evaluteClick(scope.row)">评价</el-button>
            <el-button v-if="scope.row.orderStatus === 3 && scope.row.invoiceApplyStatus === '1'" type="text" size="small" @click="invoicing(scope.row)">开票</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          class="tac mt-3"
          background
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </template>
    <order-detail ref="detailorder" v-if="page === 'detail'" :id="this.id" @goBack="goBack"></order-detail>
    <!-- 支付弹窗 -->
    <pay v-if="payVisible" ref="pay" @payCloseCallback="payCloseCallback" @paymentSuccessful="paymentSuccessful"/>
    <order-evaluate v-if="evaluteVisable" ref="evaluateOrder" @evaluateCallback="evaluateCallback"></order-evaluate>
    <el-dialog
            :visible.sync="refundVisible"
            class="feedback-dialog"
            :before-close="handleClose"
            width="30%">
      <template slot="title">
        <div class="i-title">退款</div>
      </template>
      <el-input
                  type="textarea"
                  placeholder="请输入退款原因..."
                  ref="refund"
                  v-model="refundInfo"
                  :rows="12"
                  maxlength="200"
                  show-word-limit>
      </el-input>
      <div class="mt-3 uf uf-ac uf-pc">
        <el-button style="margin-right: 15px;" @click="clear">清空</el-button>
        <el-button type="primary" @click="refundSubmit">提交</el-button>
      </div>
    </el-dialog>
<!--    发票编辑（已完成订单补开）-->
    <invoiceEditing v-if="invoiceEditingVisible" ref="invoiceEditingRef" @invoiceCallBack="invoiceCallBack"></invoiceEditing>
  </div>
</template>

<script>
  import moment from 'moment'
  import OrderDetail from './order-detail'
  import ServiceItemCascader from '../../../components/service-item-cascader'
  import Pay from '../../../service/pay'
  import orderEvaluate from './order-evaluate'
  import invoiceEditing from './invoice-apply'
  import {checkIsNull} from '../../../../utils'

  export default {
    components: {
      OrderDetail,
      ServiceItemCascader,
      Pay,
      orderEvaluate,
      invoiceEditing
    },
    data() {
      return {
        dataForm: {
          productNo: '',
          productName: '',
          itemCode: '',
          enterpriseName: '',
          productStatus: null,
          dateRange: [],
          orderStatus: []
        },
        dataTableList: [],
        dataListSelections: [],
        tagType: {1: '', 2: '', 3: 'success', 8: 'danger'},
        payTagType: {1: '', 2: 'success', 3: 'danger', 4: 'info', 5: 'warning'},
        commentType:{1:'danger',2:'success',3:'success'},
        invoiceApplyStatusType: {'1': 'info', '2': 'warning', '3': 'success', '4': 'danger'},
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        payVisible: false,
        evaluteVisable: false,
        refundVisible: false,
        refundInfo: '',
        currentId: '',
        pickerOptions: {
          shortcuts: [{
            text: '本月',
            onClick(picker) {
              let begin = new Date()
              begin.setTime(new Date(begin.getFullYear(), begin.getMonth(), 1))
              let end = new Date()
              picker.$emit('pick', [begin, end])
            }
          }, {
            text: '今年至今',
            onClick(picker) {
              const end = new Date()
              const start = new Date(new Date().getFullYear(), 0)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一年',
            onClick(picker) {
              const end = new Date()
              const start = new Date(end.getTime() - 3600 * 1000 * 24 * 365)
              picker.$emit('pick', [start, end])
            }
          }]
        },
        orderStatusDict: {},
        payStatusDict: {},
        commentStatusDict:{},
        invoiceApplyStatusDict: {},
        page: 'list',
        // 发票编辑弹框显隐
        invoiceEditingVisible: false
      }
    },
    computed: {
      accountType: {
        get() {
          return this.$store.state.user.accountType
        }
      },
      userName: {
        get() {
          return this.$store.state.user.userName
        }
      },
      mobile: {
        get() {
          return this.$store.state.user.mobile
        }
      },
      email: {
        get() {
          return this.$store.state.user.email
        }
      },
      row() {
        return this.$route.query.row
      }
    },
    created() {
      // 初始化查询时间范围
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.dateRange = [start, end]
      // 初始化字典
      this.$getDictMap('ORDER_STATUS,PAY_STATUS,ORDER_COMMENT_STATUS,INVOICE_APPLY_STATUS', (dict) => {
        this.orderStatusDict = dict.ORDER_STATUS
        this.payStatusDict = dict.PAY_STATUS
        this.commentStatusDict=dict.ORDER_COMMENT_STATUS
        this.invoiceApplyStatusDict = dict.INVOICE_APPLY_STATUS
      })
      this.getDataList(true)
      if(this.row != null) {
        this.changePage('detail', this.row)
      }
    },
    methods: {
      //跳转支付成功页面
      paymentSuccessful(orderNo, totalSum) {
        this.payVisible = false
        //延迟跳转
        this.$router.push({
          name: 'pay-successful',
          params: {
            orderNo: orderNo,
            totalSum: totalSum,
            serviceName: this.dataForm.serviceName
          }
        })
      },

      // 获取数据列表
      getDataList(refresh) {
        this.dataListLoading = true
        if (refresh) {
          this.pageIndex = 1
        }
        let [start, end] = this.dataForm.dateRange || []
        this.$http({
          url: this.$http.adornUrl(this.$api.ORDER.LIST),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'orderNo': this.dataForm.orderNo || null,
            'solutionNo': this.dataForm.solutionNo || null,
            'serviceName': this.dataForm.serviceName || null,
            'itemCode': this.dataForm.itemCode || null,
            'orderStatus': this.dataForm.orderStatus.toString() || null,
            'payStatus': this.dataForm.payStatus || null,
            'beginDate': start ? moment(start).format('YYYY-MM-DD') : null,
            'endDate': end ? moment(end).format('YYYY-MM-DD') : null
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataTableList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataTableList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle(val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList(true)
      },
      // 当前页
      currentChangeHandle(val) {
        this.pageIndex = val
        this.getDataList(false)
      },
      // 多选
      selectionChangeHandle(val) {
        this.dataListSelections = val
      },
      changePage(type, rowData) {
        this.page = type
        this.id = rowData.id
      },
      payCloseCallback(refresh) {
        if (refresh) {
          this.getDataList(refresh)
        }
      },
      // 页面回调
      goBack(refresh) {
        this.page = 'list'
        if (refresh) {
          this.getDataList(true)
        }
      },
      // 发票编辑（补开）
      invoicing (item) {
        this.invoiceEditingVisible = true
        this.$nextTick(() => {
          this.$refs.invoiceEditingRef.init(item, false)
        })
      },
      // 发票编辑（补开）--回调
      invoiceCallBack (isRefresh = false) {
        this.invoiceEditingVisible = false
        if (isRefresh) {
          this.getDataList(true)
        }
      },
      //评价
      evaluteClick(row) {
        if (row.commentStatus===2||row.commentStatus===3){
          this.$message.warning('该项目已完成评价')
        }else {
          this.evaluteVisable = true
          this.$nextTick(() => {
            this.$refs['evaluateOrder'].init(this.$deepClone(row))
          })
        }
      },
      evaluateCallback() {
        this.evaluteVisable = false
        this.getDataList(true)
      },
      // 支付
      openPayment(info) {
        // TODO 打开支付弹窗
        this.payVisible = true
        this.$nextTick(() => {
          this.$refs['pay'].init(info)
        })

        // 线下支付，暂时只做提示使用
        // this.$alert('请您按照客服人员提供的银行账户信息进行付款。', '提示', {
        //   confirmButtonText: '确定',
        // })
      },
      // 退款
      orderRefund(rowData) {
        // TODO 退款接口
      },
      handleClose(done) {
        done()
      },
      showRefund(id) {
        this.refundVisible = true
        this.currentId = id
        this.$nextTick(() => {
          this.refundInfo = ''
        })
      },
      clear() {
        this.refundInfo = ''
      },
      refundSubmit() {
        if (checkIsNull(this.refundInfo)) {
          this.$message.error('请填写退款原因  ')
          return
        }
        this.$confirm('确认申请退款?', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(this.$api.ORDER.REFUND_APPLY),
            method: 'post',
            params: this.$http.adornParams({
              'id': this.currentId,
              'reason': this.refundInfo})
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message.success('操作成功')
              this.$refs.dataForm.resetFields()
              this.$nextTick(() => {
                this.refundVisible = false
                this.getDataList()
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }).catch(() => {
        })
      },
      // 删除
      deleteHandle(id) {
        this.$confirm('确定进行删除操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(this.$api.ORDER.DELETE),
            method: 'post',
            params: this.$http.adornParams({
              'id': id
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList(true)
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>


<style scoped>
  .el-pagination {
    margin-top: 20px;
    float: right;
  }
</style>
